import React from 'react';
import Showdown from 'showdown';
import Sanitizer from 'sanitize-html';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import SEO from '../components/atoms/SEO';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.section`
  width: 100%;
  max-width: 126rem;
  padding: 4rem 3rem;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-right: 30rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-right: 20rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-right: 10rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-right: 0rem;
  }
`;

const StyledHeading = styled.h2`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.font.main};
  position: relative;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: 3rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    font-size: 3.5rem;
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3.3rem;
    height: 0.3rem;
    background-color: ${({ theme }) => theme.font.accent};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 4.3rem;
      height: 0.35rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      width: 4.8rem;
      height: 0.4rem;
    }
  }
`;

const StyledContentWrapper = styled.article`
  color: ${({ theme }) => theme.font.main};

  p {
    line-height: 2.25rem;
    font-size: 1.5rem;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      line-height: 2.7rem;
      font-size: 1.8rem;
    }

    strong {
      margin-bottom: 2rem;
    }
  }

  ul {
    padding-left: 2.5rem;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      padding-left: 3rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      padding-left: 3.5rem;
    }

    li {
      line-height: 2.25rem;
      font-size: 1.5rem;
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.m}) {
        line-height: 2.4rem;
        font-size: 1.6rem;
      }
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.l}) {
        line-height: 2.7rem;
        font-size: 1.8rem;
      }
    }
  }
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  max-height: 35rem;
`;

export const data = graphql`
  query BiedenQuery($id: String!) {
    datoCmsBieden(id: { eq: $id }) {
      heading
      text
      image {
        fluid {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
    }
  }
`;

interface Props {
  data: {
    datoCmsBieden: {
      heading: string;
      text: string;
      image: {
        fluid?: FluidObject | FluidObject[] | undefined;
      };
    };
  };
}

const BiedenPageTemplate: React.FC<Props> = ({ data }) => {
  const {
    heading,
    text,
    image: { fluid },
  } = data.datoCmsBieden;
  const html = new Showdown.Converter().makeHtml(text);
  const sanitizedHTML = Sanitizer(html);
  return (
    <>
      <SEO
        title={`${heading} - Hondentrimsalon Happy Puppy`}
        description={`${text} - Hondentrimsalon Happy Puppy`}
      />
      <StyledContainer>
        <StyledWrapper>
          <StyledHeading>{heading}</StyledHeading>
          <StyledContentWrapper
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          ></StyledContentWrapper>
          <StyledImageWrapper>
            <StyledImage fluid={fluid} />
          </StyledImageWrapper>
        </StyledWrapper>
      </StyledContainer>
    </>
  );
};

export default BiedenPageTemplate;
